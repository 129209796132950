import React from 'react'
import {Stack, Hero, Main, Sidebar, PreFooter, Layout} from '@layout'
import CardList from '@components/CardList'
import Sticky from '@components/Sticky'
import Divider from '@components/Divider'
import Pagination from '@components/Pagination'
import Seo from '@widgets/Seo'
import Categories from '@widgets/Categories'
import Tags from '@widgets/Tags'
import NewsletterCompact from '@widgets/NewsletterCompact'
import Social from '@widgets/Social'
import {useBlogTags, useBlogCategories} from '@helpers-blog'
import AdPlaceholder, {adsConfig} from "../../../ads";
import {Box, Container} from "theme-ui";

const styles = {
    heroThumbsContainer: {
        left: `50%`,
        top: `50%`,
        position: `absolute`,
        transform: `translate(-50%, -50%)`,
        display: [`none`, `none`, `none`, `block`],
    },
    heroThumbsInner: {
        width: `1/3`,
        ml: `auto`,
    },
}

const Posts = ({data: {paginatedPosts = {}, featuredPosts = {}}, ...props}) => {
    const {pageContext: {services = {}, basePath} = {}} = props
    const tags = useBlogTags()
    const categories = useBlogCategories()
    const sliderRef = React.useRef()

    return (
        <Layout {...props}>
            <Seo title='Home' />
            <Hero full sx={{ position: `relative` }}>
                <CardList
                    nodes={featuredPosts.nodes}
                    limit={3}
                    variant='horizontal-cover-hero'
                    omitFooter
                    slider
                    autoPlay
                    fade
                    dots={false}
                    arrows={false}
                    ref={sliderRef}
                />
                <Container sx={styles.heroThumbsContainer}>
                    <Box sx={styles.heroThumbsInner}>
                        <CardList
                            nodes={featuredPosts.nodes}
                            limit={3}
                            variant='horizontal-aside'
                            imageVariant='hero'
                            omitCategory
                            asNavFor={sliderRef}
                        />
                    </Box>
                </Container>
            </Hero>
            <Divider/>
            <Stack>
                <Main>
                    <CardList
                        variant={['horizontal-md', 'horizontal']}
                        title='Recently Published'
                        nodes={paginatedPosts.nodes}
                        columns={[1]}
                    />
                </Main>
                <Sidebar>
                    <AdPlaceholder {...adsConfig.home.sidebarTop} />
                    <Categories categories={categories}/>
                    <Divider/>
                    <Tags tags={tags}/>
                    <Divider/>
                    <Social/>
                    <Sticky>
                        {services.mailchimp && (
                            <>
                                <Divider/>
                                <NewsletterCompact/>
                            </>
                        )}
                        <Sticky>
                            <AdPlaceholder {...adsConfig.home.sidebarBottom} />
                        </Sticky>
                    </Sticky>
                </Sidebar>
            </Stack>
            <Divider/>
            <PreFooter>
                <Pagination {...paginatedPosts.pageInfo} basePath={basePath}/>
            </PreFooter>
            <AdPlaceholder {...adsConfig.home.bottomOfPage} />
        </Layout>
    )
}

export default Posts

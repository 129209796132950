import React from 'react'
import {Card, Text} from 'theme-ui'
import Section from '@components/Section'
import Navigation from '@components/Navigation'
import useSiteMetadata from '@helpers-blog/useSiteMetadata'
import attachSocialIcons from '@helpers/attachSocialIcons'

const Social = () => {
    const {social} = useSiteMetadata()

    return (
        <Section aside title="Follow Us">
            <Card variant='paper'>
                <Text variant='p'>
                    Follow us on social media to get updates about new content.
                </Text>
                <Navigation items={attachSocialIcons(social)} iconOnly/>
            </Card>
        </Section>
    )
}

export default Social
